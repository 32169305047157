// import js dependencies this uses modal bootstrap
// import Lightbox from "bs5-lightbox";

//swiper js
import Swiper from "swiper/bundle";
//swiper css
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";

jQuery(function ($) {
    $(".banner-swiper .swiper-wrapper").css("height", "auto");

    $.ajax({
        type: "POST",
        url: site_data.adminajax,
        data: {
            action: "basket_icon_count",
        },
    })
        .done(function (response) {
            if (response != "null") {
                $("button.cartButton ")
                    .find("svg")
                    .after(
                        '<div class="basket-item-count"><span class="cart-items-count text-white">' +
                            response +
                            "</span></div>"
                    );
            }
        })
        .fail(function () {})
        .always(function () {});

    //homepageBanner
    if ($(".homebanner-swiper").length) {
        const homeBannner = new Swiper(".homebanner-swiper", {
            speed: 1000,
            slidesPerView: 1,
            autoplay: {
                delay: 5000,
            },
            loop: false,
            effect: "fade",
            fadeEffect: {
                crossFade: true,
            },
            pagination: {
                el: ".homepageBanner-swiper-pagination",
                type: "bullets",
                clickable: true,
            },
        });

        const homeTextBannner = new Swiper(".hometextbanner-swiper", {
            speed: 1000,
            slidesPerView: 1,
            loop: false,
            effect: "fade",
            fadeEffect: {
                crossFade: true,
            },
        });

        //sync sliders
        homeTextBannner.controller.control = homeBannner;
        homeBannner.controller.control = homeTextBannner;
    }

    if ($(".banner-swiper").length) {
        //high level banner slider
        const swiperBannner = new Swiper(".banner-swiper", {
            speed: 1000,
            slidesPerView: 1,
            autoplay: {
                delay: 4000,
            },
            loop: true,
        });
    }
});
